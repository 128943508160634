import React from "react";
import { Waypoint } from "react-waypoint";

import EmptySpace from "~/components/_emptySpace";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import Spinner from "~/components/_spinner";
import AssetRow from "~/components/asset/AssetRow";
import { MachineLoaderComponent } from "~/components/skeletonLoaders/_machineLoders";
import { ITEMS_PER_PAGE } from "~/constants/global";
import { NoUserAssignedIcon } from "~/icons";

export default ({
  intl,
  machines,
  loading,
  totalCount,
  handleFetchMore,
  searchValue = "",
}) => {
  const handleScrollToBottom = (event) => {
    if (
      machines.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      machines.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: machines.length,
      });
    }
  };

  if (loading && !machines.length) {
    return <Spinner />;
  }

  return (
    <div>
      {machines?.length === 0 && !loading ? (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={
            searchValue
              ? intl.messages?.common?.noResultFound
              : intl.messages?.hierarchy?.assets?.noAssets
          }
        />
      ) : (
        <div className="flex flex-col space-y-sm">
          {machines?.map((item) => (
            <AssetRow
              key={item._id}
              intl={intl}
              asset={item}
              isAssetQuery={!!searchValue}
            />
          ))}
          <Waypoint onEnter={handleScrollToBottom} />
        </div>
      )}
      {loading && <MachineLoaderComponent total={1} />}
      <EmptySpace height="30px" />
    </div>
  );
};
