import { updateAssetCache } from "~/services";

export const documentFoldersListener = (m) => {
  if (!m?.message?.success) {
    return;
  }

  const payload = m?.message?.payload;
  const isMachine = m?.userMetadata?.isMachine;

  if (isMachine) {
    updateAssetCache(payload);
  }
};
