import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import PAID_FEATURES from "$/settings/paid-features.json";
import { JWT } from "~/apollo/_config";
import EmptySpace from "~/components/_emptySpace";
import { useAuth } from "~/components/general";
import { COLOR } from "~/constants";
import { HourglassIcon } from "~/icons";
import { useGetMachine3DAuthToken } from "~/services";
import { getEnums } from "~/utils";

const paidFeatures = getEnums(PAID_FEATURES, "reference");
const Webvis = ({ url, onRequestPart, machine }) => {
  const webvisComponent = useRef(null);
  const initilize = useRef(false);
  const { messages } = useIntl();
  const { token } = useGetMachine3DAuthToken(machine?._id);
  const [showLoadingAlert, setShowLoadingAlert] = useState(true);
  const { user } = useAuth();
  const isWorkManagementPaid = user.oem.paidFeatures.includes(
    paidFeatures.workManagement,
  );
  async function init() {
    if (!webvisComponent.current) return;

    const myContext = await webvisComponent.current.requestContext();
    myContext?.changeSetting(webvis.SettingStrings.ADDITIONAL_REQUEST_HEADERS, {
      [JWT.HEADER.TOKEN.NAME]:
        localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME) || null,
    });
    webvisComponent.current.hideAddButton();
    webvisComponent.current.hideBottomBar();
    webvisComponent.current.hideSearch();
    webvisComponent.current.hideGizmo();
    webvisComponent.current.hideToolbar();
    if (myContext !== null) {
      await myContext.clear();
      myContext.changeSetting(
        webvis.SettingStrings.ADDITIONAL_REQUEST_HEADERS,
        {
          [JWT.HEADER.TOKEN.NAME]: token || null,
        },
      );
      await myContext.add({
        autoExpand: true,
        label: machine?.name,
        dataURI: url,
        initialProperties: {
          enabled: true,
        },
      });

      const children = await myContext.getProperty(0, webvis.Property.CHILDREN);

      await myContext.registerListener(
        [webvis.EventType.NODE_CHANGED],
        function (event) {
          if (event?.newValue >= 3000) {
            setShowLoadingAlert(false);
          }
        },
        children?.[0] ?? 0,
      );

      webvisUI.setSetting(
        webvisUI.UISetting.CONTEXT_MENU_FUNCTION,
        (entries) => {
          let newEntries = [];
          if (isWorkManagementPaid)
            newEntries.push({
              label: messages?.tickets?.SparePartOrder,
              command: async (nodeId) => {
                let label = "";
                try {
                  label = await myContext.getProperty(
                    nodeId,
                    webvis.Property.LABEL,
                  );
                } catch (error) {
                  //
                } finally {
                  onRequestPart(label || "");
                }
              },
            });
          return [
            ...entries.filter((entry) =>
              entry?.label?.toLowerCase()?.includes("fly_to"),
            ),
            ...newEntries,
          ];
        },
      );
    }

    initilize.current = true;
  }

  useEffect(() => {
    if (!token) return;
    const script = document.createElement("script");
    script.src = import.meta.env.VITE_APP_3D_SERVER_URL;

    // Append the script element to the document body
    document.body.appendChild(script);
    script.onload = () => {
      init();
    };

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  if (!token) return null;

  return (
    <>
      <div className="vis-wrapper">
        <div className="vis-view">
          <webvis-full
            ref={webvisComponent}
            context="default_context"
            viewer="default_viewer"
          ></webvis-full>
        </div>

        {showLoadingAlert && (
          <div className={"alert-modal-overlay active u-absolute"}>
            <div className={"alert-box-wrapper small-alert"}>
              <div className="u-flex u-width-100 u-flex-column u-items-center">
                <HourglassIcon
                  width={"18"}
                  height={"20"}
                  fillColor={COLOR.$blue_v2_100}
                />
                <EmptySpace height="16px" />
                <Headline size={HEADLINE_SIZES.X_SMALL}>
                  {messages.machines._3DModelLoadingAlert.title}
                </Headline>
                <EmptySpace height="4px" />
                <Label
                  size={LABEL_SIZES.X_SMALL}
                  color="text-secondary"
                  className="font-medium text-center"
                >
                  {messages.machines._3DModelLoadingAlert.message}
                </Label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Webvis;
