import ChevronRight from "@shared/svg/chevron-right.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { getMachineThumbnail } from "~/utils";

const MachineListItem = ({
  intl,
  machine,
  variant = "small",
  inline,
  showExpandIcon = false,
  expanded = false,
  setExpanded = () => {},
  isAssetQuery = true,
}) => {
  const { messages } = useIntl();

  const thumbnail = React.useMemo(() => {
    return getMachineThumbnail(machine);
  }, [machine]);

  return (
    <Link to={`/${intl?.locale}/app/assets/machines/${machine?._id}`}>
      <div className="list-item--boxed u-flex u-items-center machine-list-item !mb-0">
        {showExpandIcon && !isAssetQuery && (
          <ChevronRight
            className={`flex-shrink-0 w-lg h-lg duration-300 ease-in-out transition-transform stroke-secondary mr-sm ${
              expanded ? "rotate-90" : ""
            }`}
            width={16}
            height={16}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setExpanded();
            }}
          />
        )}
        <div className="u-flex u-items-center u-flex-grow-1">
          <figure className={`machine-thumbnail machine-thumbnail--${variant}`}>
            <img
              src={thumbnail}
              alt={machine?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
          <div className="u-flex u-flex-column machine-list-item-title--container">
            <div
              className={`inline-flex ${
                isAssetQuery ? "flex-col" : "items-center"
              }`}
            >
              <Headline
                size={HEADLINE_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis whitespace-nowrap hidden md:flex"
                title={machine?.name}
              >
                {machine?.name}
              </Headline>
              <Label
                size={LABEL_SIZES.SMALL}
                className="w-full line-clamp-2 text-ellipsis whitespace-normal mb-xs md:hidden"
                title={machine?.name}
              >
                {machine?.name}
              </Label>
              <div
                className={`machine-serial-number u-flex u-flex-column sm-mobile-element ${
                  inline ? "inline" : ""
                }`}
              >
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {messages?.machines?.machineDetails.detailsTab["sn"]}
                  {inline && <>: &nbsp;</>}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  className="truncate max-w-20"
                  title={machine?.serialNumber}
                >
                  {machine?.serialNumber}
                </BodyText>
              </div>

              {isAssetQuery && (
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  className="mt-xs md:mt-0 truncate w-full"
                  color="text-secondary"
                >
                  {machine.hierarchy?.map((item) => item.name).join(" > ")}
                </BodyText>
              )}
            </div>
          </div>
        </div>

        <div className="u-flex u-items-center u-padding-r-5 sm-desktop-element u-content-box w200">
          <div className="flex space-x-sm mr-0">
            {!!machine.totalChildrenCount && (
              <div className="flex items-center space-x-xs justify-between mr-4">
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-primary">
                  {machine.totalChildrenCount}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="truncate"
                >
                  {
                    messages["hierarchy"]["assets"][
                      machine.totalChildrenCount > 1 ? "subAssets" : "subAsset"
                    ]
                  }
                </BodyText>
              </div>
            )}
            <div
              className={`machine-serial-number u-flex u-flex-column ${
                inline ? "inline" : ""
              }`}
            >
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages?.machines?.machineDetails.detailsTab["sn"]}
                {inline && <>: &nbsp; &nbsp;</>}
              </BodyText>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="truncate max-w-20"
                title={machine?.serialNumber}
              >
                {machine?.serialNumber}
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MachineListItem;
