import MachineIconFilled from "@shared/svg/machines-filled.svg?react";
import MachineIcon from "@shared/svg/machines.svg?react";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";

import Assets from "~/containers/_assets";
import MachinesQrcSingle from "~/containers/_machinesQrcSingle.jsx";
import MachinesSingle from "~/containers/_machinesSingle.jsx";

export const machinesRoutes = (WithHeader) => {
  return [
    {
      id: "app",
      path: "/app/assets",
      exact: true,
      label: "machines",
      icon: <MachineIcon className="icon w-xl h-xl stroke-secondary" />,
      iconActive: (
        <MachineIconFilled className="!stroke-none icon-active-assets w-xl h-xl" />
      ),
      showInNavigation: true,
      Main: (props) => (
        <WithHeader>
          <Assets {...props} />
        </WithHeader>
      ),
    },
    {
      id: "app",
      path: "/app/assets/machines",
      exact: true,
      label: "machines",
      showInNavigation: false,
      Main: (props) => <Redirect to={`/${props?.intl?.locale}/app/assets`} />,
    },
    {
      id: "app",
      path: "/app/assets/production-lines",
      exact: true,
      label: "machines",
      showInNavigation: false,
      Main: (props) => <Redirect to={`/${props?.intl?.locale}/app/assets`} />,
    },
    {
      id: "app",
      path: "/app/assets/view/:machineUuid",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => <MachinesQrcSingle {...props} />,
    },
    {
      id: "app",
      path: "/app/machines/view/:machineUuid",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => {
        const params = useParams();

        return (
          <Redirect
            to={`/${props?.intl?.locale}/app/assets/view/${params.machineUuid}`}
          />
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/all/:id",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => (
        <WithHeader {...props}>
          <MachinesSingle {...props} />
        </WithHeader>
      ),
    },
    {
      id: "app",
      path: "/app/assets/machines/:id",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => {
        const params = useParams();

        return (
          <Redirect
            to={`/${props?.intl?.locale}/app/assets/all/${params.id}`}
          />
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/machines/:machineId/component/:id",
      exact: true,
      label: "Component",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => {
        const params = useParams();

        return (
          <Redirect
            to={`/${props?.intl?.locale}/app/assets/all/${params.id}`}
          />
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/production-lines/:id",
      exact: true,
      label: "Production Lines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      Main: (props) => {
        const params = useParams();

        return (
          <Redirect
            to={`/${props?.intl?.locale}/app/assets/all/${params.id}`}
          />
        );
      },
    },
  ];
};
