import { GET_OWN_CUSTOMER_ASSET } from "~/api";
import client from "~/apollo/_client";

export const updateAssetCache = (payload) => {
  const asset = client.readQuery({
    query: GET_OWN_CUSTOMER_ASSET,
    variables: { id: payload._id },
  });

  if (!asset) return;

  const { getOwnCustomerAssetById: cachedAsset } = asset;
  if (cachedAsset) {
    const cloned = { ...cachedAsset };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_CUSTOMER_ASSET,
      variables: { id: payload._id },
      data: {
        getOwnCustomerAssetById: cloned,
      },
    });
  }
};
