import { useQuery } from "@apollo/client";

import {
  GET_MACHINE_3D_AUTH_TOKEN_QUERY,
  GET_OWN_CUSTOMER_ASSET,
  GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY,
  GET_OWN_CUSTOMER_ASSET_PARTS,
  GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY,
  LIST_OWN_CUSTOMER_ASSETS,
  LIST_OWN_OEM_MACHINE_HISTORY,
} from "~/api";
import { ITEMS_PER_PAGE } from "~/constants/global";

export const useGetAssetById = ({
  id,
  skipCondition = false,
}: {
  id: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetById: asset = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_ASSET, {
      variables: {
        id,
      },
      skip: !id || skipCondition,
    });

  return [asset, loading];
};

export const useGetAssetPartsById = ({
  id,
  skipCondition = false,
}: {
  id: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetById: asset = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_ASSET_PARTS, {
      variables: {
        id,
      },
      skip: !id || skipCondition,
    });

  return [asset?.inventoryParts, loading];
};

export const useGetAssetByUuid = ({
  uuid,
  skipCondition = false,
}: {
  uuid: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetByUuid: asset = {} } = {} } = useQuery(
    GET_OWN_CUSTOMER_ASSET_BY_UUID_QUERY,
    {
      variables: {
        uuid,
      },
      skip: !uuid || skipCondition,
    },
  );

  return [asset];
};

export const useGetAssetPartsByUuid = ({
  uuid,
  skipCondition = false,
}: {
  uuid: string;
  skipCondition?: boolean;
}) => {
  const { data: { getOwnCustomerAssetByUuid: asset = {} } = {} } = useQuery(
    GET_OWN_CUSTOMER_ASSET_PARTS_BY_UUID_QUERY,
    {
      variables: {
        uuid,
      },
      skip: !uuid || skipCondition,
    },
  );

  return [asset?.inventoryParts];
};

export const useGetAssetsByParams = ({
  where = null,
  limit = ITEMS_PER_PAGE,
  skip = 0,
  skipCondition = false,
}) => {
  const variables = {
    params: {
      ...(where ? { where } : {}),
      limit,
      skip,
    },
  };

  const {
    data: { listOwnCustomerAssets = {} } = {},
    loading,
    fetchMore,
    error,
  } = useQuery(LIST_OWN_CUSTOMER_ASSETS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: skipCondition,
  });

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...variables,
        params: {
          ...variables.params,
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedAssets = [
          ...previousResult.listOwnCustomerAssets.assets,
          ...fetchMoreResult.listOwnCustomerAssets.assets,
        ];

        const updates = {
          ...previousResult,
          listOwnCustomerAssets: {
            ...previousResult.listOwnCustomerAssets,
            ...fetchMoreResult.listOwnCustomerAssets,
            assets: mergedAssets,
          },
        };

        return updates;
      },
    });
  };

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    assets = [],
  } = listOwnCustomerAssets;

  return {
    assets,
    loading,
    error,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  };
};

export const useGetMachine3DAuthToken = (id) => {
  const { data: { getMachine3DAuthToken } = {}, loading } = useQuery(
    GET_MACHINE_3D_AUTH_TOKEN_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: "network-only",
    },
  );

  return {
    loading,
    token: getMachine3DAuthToken,
  };
};

export const useListOwnOemMachineHistory = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery = "",
  where = null,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemMachineHistory = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_MACHINE_HISTORY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    history = [],
    ticketCount,
  } = listOwnOemMachineHistory ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    history: [],
    ticketCount: 0,
  };

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedHistory = [
          ...previousResult.listOwnOemMachineHistory.history,
          ...fetchMoreResult.listOwnOemMachineHistory.history,
        ];

        const updates = {
          ...previousResult,
          listOwnOemMachineHistory: {
            ...previousResult.listOwnOemMachineHistory,
            ...fetchMoreResult.listOwnOemMachineHistory,
            history: mergedHistory,
          },
        };

        return updates;
      },
    });
  };

  return {
    history,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
    ticketCount,
  };
};
