import * as React from "react";
const SvgAiSparkles = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 48,
  height: 48,
  viewBox: "0 0 48 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("rect", {
  width: 48,
  height: 48,
  rx: 24,
  fill: "#8940E5"
}), /* @__PURE__ */ React.createElement("rect", {
  x: 8,
  y: 8,
  width: 32,
  height: 32,
  rx: 4,
  fill: "#8940E5"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M17.997 14.996a4.002 4.002 0 0 1-4.001 4.002A4.002 4.002 0 0 1 17.997 23 4.002 4.002 0 0 1 22 18.998a4.002 4.002 0 0 1-4.002-4.002ZM29.002 27.002A5.002 5.002 0 0 0 24 21.998a5.002 5.002 0 0 0 5.002-5.002A5.002 5.002 0 0 0 34.004 22a5.002 5.002 0 0 0-5.002 5.003Z",
  fill: "#fff",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M29.002 27.002v1M29.002 16.997v-1M22 33.004v1M22 26v-1M17.997 23v1M17.997 13.996v1",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M22 25.5a4.002 4.002 0 0 1-4.003 4.002A4.002 4.002 0 0 1 22 33.504a4.002 4.002 0 0 1 4.002-4.002 4.002 4.002 0 0 1-4.002-4.001Z",
  fill: "#fff",
  stroke: "#fff",
  strokeWidth: 1.5,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAiSparkles;
