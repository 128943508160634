import AppHeader from "@shared/ui/AppHeader";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { SearchInput } from "@shared/ui/Inputs";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import MachinesListing from "~/containers/_machinesListing.jsx";
import { useGetAssetsByParams } from "~/services/asset";

const Assets = ({ intl }) => {
  const { messages } = useIntl();
  const [searchValue, setSearchValue] = useState("");

  const {
    assets,
    totalCount: assetsTotalCount,
    loading: assetsLoading,
    handleFetchMore: assetsHandleFetchMore,
  } = useGetAssetsByParams({
    where: {
      searchQuery: searchValue,
      ...(searchValue ? {} : { assetAssigned: false }),
    },
  });

  const onSearch = (value) => setSearchValue(value);

  return (
    <>
      <AppHeader
        padding="py-xl lg:py-lg"
        className="mx-xl md:mx-2xl max-lg:mb-sm"
      >
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.header.machines.title}
        </Headline>
        <div className="flex items-center desktop-element">
          <SearchInput
            onChange={onSearch}
            value={searchValue}
            placeholder={messages?.machines?.searchPlaceholder}
          />
        </div>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>
      {/* MobileSearch component is only for mobile view */}
      <MobileSearch onChange={onSearch} value={searchValue} />
      <div className="mt-lg px-2xl">
        <MachinesListing
          intl={intl}
          machines={assets}
          loading={assetsLoading}
          totalCount={assetsTotalCount}
          handleFetchMore={assetsHandleFetchMore}
          searchValue={searchValue}
        />
      </div>
    </>
  );
};

export default Assets;
