import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { X } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import Modal from "react-modal";

type AppModalPropsType = {
  isOpen: boolean;
  overlayClasses?: string;
  className?: string;
  header?: React.ReactNode;
  title?: string;
  content?: React.ReactNode;
  contentClassName?: string;
  footer?: React.ReactNode;
  showFooter?: boolean;
  footerClassName?: string;
  hideCancelButton?: boolean;
  cancelButtonText?: string;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  disableUpdate?: boolean;
  disableCancel?: boolean;
  hideUpdateButton?: boolean;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  updateButtonContent?: React.ReactNode;
  updateButtonText?: string;
  maxWidth?: string;
  shouldCloseOnOverlayClick?: boolean;
};

const AppModal = ({
  isOpen,
  overlayClasses = "",
  className = "",
  header = null,
  title = "",
  content = null,
  contentClassName = "",
  footer = null,
  showFooter = true,
  hideCancelButton = false,
  cancelButtonText = "",
  handleClose = () => {},
  disableUpdate = false,
  disableCancel = false,
  hideUpdateButton = false,
  handleSubmit = () => {},
  updateButtonContent = null,
  updateButtonText = "",
  maxWidth = "!max-w-[700px]",
  shouldCloseOnOverlayClick = false,
  footerClassName = "",
}: AppModalPropsType) => {
  const { messages } = useIntl();

  return (
    <Modal
      preventScroll
      className={`top-4xl left-1/2 w-[calc(100%-30px)] m-0 border-0 rounded-lg !p-0 !translate-x-0 flex flex-col max-h-[90vh] overflow-x-hidden overflow-y-auto bg-primary focus:outline-none ${className} ${maxWidth}`}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      appElement={document.querySelector(".ReactModalPortal")}
      onRequestClose={handleClose}
      overlayClassName={`fixed inset-0 flex items-center justify-center z-10 bg-secondary/60 ${overlayClasses}`}
    >
      {header || (
        <div className="flex items-center justify-between py-md md:py-xl px-2xl border-solid border-b border-t-0 border-x-0 border-primary">
          <Headline size={HEADLINE_SIZES.X_SMALL}>{title}</Headline>

          <button
            onClick={handleClose}
            className="w-3xl h-3xl flex items-center justify-center bg-primary border border-solid border-primary rounded-lg cursor-pointer transition-all duration-200 ease-in-out hover:border-brand"
          >
            <X size={16} className="text-primary" />
          </button>
        </div>
      )}

      {content && (
        <div className={`p-2xl overflow-y-auto ${contentClassName}`}>
          {content}
        </div>
      )}

      {footer || (
        <>
          {showFooter && (
            <div
              className={`flex items-center justify-end py-lg px-2xl border-solid border-t border-b-0 border-x-0 border-primary gap-sm ${footerClassName}`}
            >
              {!hideCancelButton && (
                <Button
                  variant={BUTTON_VARIANTS.OUTLINE}
                  text={cancelButtonText || messages?.common?.cancel}
                  disabled={disableUpdate && disableCancel}
                  onClick={handleClose}
                />
              )}

              {!hideUpdateButton &&
                (updateButtonContent || (
                  <Button
                    text={updateButtonText || messages?.common?.update}
                    disabled={disableUpdate}
                    onClick={handleSubmit}
                  />
                ))}
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default AppModal;
