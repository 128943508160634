import { bottom } from "@popperjs/core";
import AiSparklesIcon from "@shared/svg/ai-sparkles.svg?react";
import CommunityIcon from "@shared/svg/community-icon.svg?react";
import PdfDocument from "@shared/svg/pdf-document.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Overlay from "@shared/ui/Overlay";
import Tag from "@shared/ui/Tag";
import TextEditor from "@shared/ui/TextEditor";
import moment from "moment-timezone";
import {
  ArrowsInSimple,
  ArrowsOutSimple,
  DiamondsFour,
  FolderOpen,
  Wrench,
} from "phosphor-react";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { useRecoilState } from "recoil";

import COLOR_PALETTE from "$/settings/color-palette.json";
import HISTORY_TYPES from "$/settings/enums/history/machineHistory.json";
import { imagePreviewAtom } from "~/atoms/_chat";
import EmptySpace from "~/components/_emptySpace";
import { TicketCard } from "~/components/_ticketCard";
import { useAuth } from "~/components/general";
import { MachineHistoryLoading } from "~/components/machine/loader/MachineHistoryLoading";
import WorkOrderTypeIcons from "~/components/WorkOrderTypeIcons";
import { WORK_ORDER_TYPE_ICONS } from "~/constants";
import { COLOR } from "~/constants/colors";
import {
  DATE_FORMAT_DD_MM_YYYY,
  ITEMS_BEFORE_PAGE,
  ITEMS_PER_PAGE,
  MOBILE_UI_MAX_WIDTH,
  TIME_FORMAT,
} from "~/constants/global";
import { useGetClosedStatus } from "~/hooks";
import useWindowDimensions from "~/hooks/_useWindowDimensions";
import { HourglassIcon, ProceduresIcon } from "~/icons";
import { useListOwnOemMachineHistory } from "~/services/asset";
import { generateStatusBackgroundColor, getEnums } from "~/utils";
import { trimText } from "~/utils/_initials";

const HISTORY_TYPES_ENUMS = getEnums(HISTORY_TYPES, "reference");

export const TicketIcons = {
  ServiceRequest: <FolderOpen size={14} className="u-text-color-blue-v2-100" />,
  SparePartOrder: <Wrench size={14} />,
  PreventiveMaintenance: <HourglassIcon />,
  FormSubmission: "PDF",
  Procedure: <ProceduresIcon strokeColor={COLOR.$red_v2_50} />,
};

export const MachineHistory = ({ intl, machine }) => {
  const { messages } = useIntl();
  const { user } = useAuth();
  const { width: deviceWidth } = useWindowDimensions();
  const { months } = messages?.common;
  const [isMobileDevice, setIsMobileDevice] = useState(
    deviceWidth <= MOBILE_UI_MAX_WIDTH,
  );

  const { closedStatus } = useGetClosedStatus();

  const { history, ticketCount, loading, totalCount, handleFetchMore } =
    useListOwnOemMachineHistory({
      limit: ITEMS_PER_PAGE,
      skip: 0,
      where: {
        machine: machine?._id ?? null,
      },
    });

  const handleScrollBottom = (event) => {
    if (
      history.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      totalCount > history.length
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: history.length,
      });
    }
  };

  const objectIdsToShowMonthWith = useMemo(() => {
    let currentMonth = -1;
    let currentYear = -1;

    return history
      .filter((item, index) => {
        if (item.month !== currentMonth || item.year !== currentYear) {
          currentMonth = item.month;
          currentYear = item.year;
          return true;
        }
        return false;
      })
      .map((item) => item._id);
  }, [history]);

  const getUrl = (item) => {
    switch (item.type) {
      case HISTORY_TYPES_ENUMS.FormSubmission:
      case HISTORY_TYPES_ENUMS.Procedure:
        return null;
      default: {
        const ticketSlug =
          item.status === closedStatus?._id
            ? "closed-work-orders"
            : "work-orders";
        return `/${intl?.locale}/app/${ticketSlug}/${item?.resource?._id}`;
      }
    }
  };

  const prepareType = (history) => {
    switch (history.type) {
      case HISTORY_TYPES_ENUMS.Procedure:
        return {
          icon: WORK_ORDER_TYPE_ICONS.PROCEDURE,
          color: COLOR_PALETTE.red.primary,
          isInternal: !!history.ticket?.ticketType?.isInternal,
        };

      case HISTORY_TYPES_ENUMS.FormSubmission:
        return {
          icon: WORK_ORDER_TYPE_ICONS.FORM_SUBMISSION,
          color: COLOR_PALETTE.gray.primary,
          isInternal: history.ticket?.ticketType?.isInternal,
        };

      default:
        return (
          user?.oem?.ticketTypes?.find(
            (ticketType) => history.resource?.ticketType === ticketType._id,
          ) || {}
        );
    }
  };

  React.useEffect(() => {
    setIsMobileDevice(deviceWidth <= MOBILE_UI_MAX_WIDTH);
  });

  if (!history.length && loading) {
    return <MachineHistoryLoading />;
  }

  return (
    <>
      {history.map?.((item, index) => {
        const url = getUrl(item);
        const type = prepareType(item);
        const status =
          user?.oem?.statuses?.find(
            (statusItem) => item?.resource?.status === statusItem._id,
          ) || {};

        const props = {
          icon: TicketIcons[item?.type] || TicketIcons.ServiceRequest,
          title: item?.resource?.title ?? item?.ticket?.title,
          status: status,
          facility: item?.customer?.name,
          machine: machine?.name,
          date: item?.createdAt?.split("T")?.[0],
          ticketID: item?.resource?.id ?? item?.ticket?.ticketId,
          ticketType: type,
          hideAssignees: true,
        };

        return (
          <Fragment key={item._id}>
            <div className="month-wise-ticket-wrapper" key={index}>
              {objectIdsToShowMonthWith.includes(item._id) && (
                <div className="absolute top-0 left-0 flex flex-col justify-center w-7 py-2xs bg-primary max-md:relative max-md:flex-row max-md:items-baseline max-md:justify-start max-md:w-full max-md:p-0">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    className="text-center max-md:hidden"
                  >
                    {months[item?.month]}
                  </BodyText>
                  <Label
                    size={LABEL_SIZES.MEDIUM}
                    className="text-center md:hidden"
                  >
                    {months[item?.month]}
                  </Label>{" "}
                  <Label
                    size={LABEL_SIZES.SMALL}
                    color="text-secondary"
                    className="text-center w-full font-medium max-md:w-auto max-md:ml-2xs "
                  >
                    {item?.year}
                  </Label>
                </div>
              )}
              {[HISTORY_TYPES_ENUMS.AI, HISTORY_TYPES_ENUMS.Note].includes(
                item.type,
              ) ? (
                <HistoryNoteItem item={item} />
              ) : (
                <>
                  {!isMobileDevice ? (
                    <MachineHistoryItem
                      key={index}
                      history={item}
                      type={type}
                      status={status}
                      url={url}
                      machine={machine}
                    />
                  ) : (
                    <Link
                      key={index}
                      to={url}
                      onClick={(e) => {
                        if (
                          (item?.type === HISTORY_TYPES_ENUMS.FormSubmission ||
                            item?.type === HISTORY_TYPES_ENUMS.Procedure) &&
                          item?.url
                        ) {
                          e.preventDefault();
                          window.open(item?.url, "_blank");
                        }
                      }}
                    >
                      <TicketCard {...props} key={index} />
                    </Link>
                  )}
                </>
              )}
            </div>

            {!loading &&
              totalCount > history.length &&
              index === history.length - ITEMS_BEFORE_PAGE && (
                <Waypoint onEnter={handleScrollBottom} />
              )}
          </Fragment>
        );
      })}
      {history.length > 0 && loading && <MachineHistoryLoading />}
      {totalCount === history.length && (
        <div className="month-wise-ticket-wrapper machine-info">
          <div className="absolute top-0 left-0 flex flex-col justify-center w-7 bg-primary max-md:relative max-md:flex-row max-md:items-baseline max-md:justify-start max-md:w-full">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="text-center max-md:hidden"
            >
              {months[new Date(machine?.createdAt).getMonth() || ""]}
            </BodyText>
            <Label size={LABEL_SIZES.MEDIUM} className="text-center md:hidden">
              {months[new Date(machine?.createdAt).getMonth() || ""]}
            </Label>{" "}
            <Label
              size={LABEL_SIZES.SMALL}
              color="text-secondary"
              className="text-center w-full font-medium max-md:w-auto max-md:ml-2xs "
            >
              {new Date(machine?.createdAt).getFullYear() || ""}
            </Label>
          </div>

          <a href="#">
            <div className="ticket-machine-info">
              <span className="machine-icon">
                <DiamondsFour size={20} />
              </span>
              <span>
                {
                  messages?.machines?.machineDetails.historyTab
                    .machineCreationDate
                }{" "}
                &nbsp;
              </span>{" "}
              <span className="machine-creation-date">
                {moment(machine?.createdAt).format("DD.MM.YYYY")}
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

const MachineHistoryItem = ({ history, type, status, url, machine }) => {
  const { messages } = useIntl();
  const [isHovered, setIsHovered] = useState(false);

  const iconStyles = {
    backgroundColor: isHovered
      ? type.color
      : generateStatusBackgroundColor(type.color),
  };

  return (
    <Link
      to={url}
      onClick={(e) => {
        if (
          (history?.type === HISTORY_TYPES_ENUMS.FormSubmission ||
            history?.type === HISTORY_TYPES_ENUMS.Procedure) &&
          history?.resource?.pdfUrl
        ) {
          e.preventDefault();
          window.open(history?.resource?.pdfUrl, "_blank");
        }
      }}
      className="ticket-item-wrapper"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <div className="left-side-content">
        <span
          className={"ticket-icon btn-v2 secondary-icon-btn-v2"}
          style={iconStyles}
        >
          <WorkOrderTypeIcons
            size={24}
            strokeColor={isHovered ? COLOR.$white_v2 : type.color}
            icon={type.icon}
          />
        </span>
        <div className="title-wrapper">
          <Headline
            size={HEADLINE_SIZES.X_SMALL}
            className="mb-2xs overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {trimText(history?.resource?.name ?? history?.resource?.title, 75)}
          </Headline>
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="no-styles"
          >
            #{history?.resource?.id ?? history?.ticket?.ticketId} &nbsp; &#8226;
            &nbsp; {machine?.name}
          </BodyText>
        </div>
      </div>
      <div className="right-side-content">
        <div className="ticket-meta-data">
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="no-styles">
            <span className="text-secondary">
              {messages?.tickets?.creationDate}:
            </span>{" "}
            {history.createdAt?.split("T")?.[0]}
          </BodyText>
        </div>
        {history?.type !== HISTORY_TYPES_ENUMS.FormSubmission && (
          <div
            className="ticket-status-comment"
            style={{ alignSelf: "flex-start" }}
          >
            {history?.type !== HISTORY_TYPES_ENUMS.Procedure ? (
              <Tag
                label={status?.label}
                className="!mb-0"
                style={{
                  color: isHovered ? COLOR.$white_v2 : status.color,
                  backgroundColor: isHovered
                    ? status.color
                    : generateStatusBackgroundColor(status.color),
                }}
              />
            ) : (
              <Tag
                label={history?.resource?.state}
                className={"!mb-0 " + history?.status}
                style={{
                  color: isHovered
                    ? COLOR.$white_v2
                    : COLOR_PALETTE.green.primary,
                  backgroundColor: isHovered
                    ? COLOR_PALETTE.green.primary
                    : COLOR_PALETTE.green.light,
                }}
              />
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

const HistoryNoteItem = ({ item }) => {
  const { messages } = useIntl();

  const [isEditorScrolling, setIsEditorScrolling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAiNoteOvrelay, setShowAiNoteOvrelay] = useState(false);
  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);

  const aiIconRef = useRef();

  return (
    <>
      <div className="bg-gray-5 border border-solid border-gray-10 w-full p-4 rounded-lg">
        <div className="md:gap-4 gap-2 flex w-full break-all">
          <div className="bg-gray-20 flex w-8 h-8 min-w-8 rounded-full">
            <CommunityIcon className="m-auto" />
          </div>
          <div className="w-full mt-1 flex flex-col">
            <div className="flex w-full">
              <div className="flex mb-2">
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  {moment(item.createdAt).format(DATE_FORMAT_DD_MM_YYYY)}{" "}
                  &nbsp;&#8226;&nbsp;
                  {moment(item.createdAt).format(TIME_FORMAT)}
                  &nbsp;&#8226;&nbsp;
                  {item.createdBy.name}
                </BodyText>
              </div>
              <div className="flex ml-auto">
                {isEditorScrolling && (
                  <>
                    {isExpanded ? (
                      <ArrowsInSimple
                        size={16}
                        className="u-text-color-gray-v2-60 cursor-pointer"
                        onClick={() => setIsExpanded(false)}
                      />
                    ) : (
                      <ArrowsOutSimple
                        size={16}
                        className="u-text-color-gray-v2-60 cursor-pointer"
                        onClick={() => setIsExpanded(true)}
                      />
                    )}
                  </>
                )}
                {item.type === HISTORY_TYPES_ENUMS.AI && (
                  <>
                    <div
                      onMouseEnter={() => setShowAiNoteOvrelay(true)}
                      onMouseLeave={() => setShowAiNoteOvrelay(false)}
                      ref={aiIconRef}
                      className="flex"
                    >
                      <AiSparklesIcon className="h-3xl w-3xl" />
                    </div>
                    {showAiNoteOvrelay && (
                      <Overlay
                        arrowClasses="before:bg-secondary before:shadow-xl"
                        className="bg-secondary rounded-md py-2xs px-sm shadow-xl w-fit z-10 max-w-96xl"
                        containerRef={document.body}
                        placement={bottom}
                        offset={[0, 8]}
                        showOverlay={showAiNoteOvrelay}
                        targetRef={aiIconRef}
                      >
                        <div
                          className="relative"
                          onMouseEnter={() => setShowAiNoteOvrelay(true)}
                          onMouseLeave={() => setShowAiNoteOvrelay(false)}
                        >
                          <BodyText
                            className="!text-inverse"
                            size={BODY_TEXT_SIZES.X_SMALL}
                          >
                            {messages["assets"]["history-note-overlay-text"]}
                          </BodyText>
                        </div>
                      </Overlay>
                    )}
                  </>
                )}
              </div>
            </div>
            <TextEditor
              keyId="note-detail"
              mainEditorClassName={`${
                isExpanded ? "!max-h-max" : "overflow-y-hidden"
              }`}
              placeholder={" "}
              content={item?.note?.message ?? ""}
              onUpdate={() => {}}
              readOnly
              setIsScrolling={setIsEditorScrolling}
            />
            {item?.note.attachments?.length > 0 && (
              <>
                <EmptySpace size="16px" />
                <div className="w-full gap-3 flex flex-wrap">
                  {item?.note.attachments.map((attachment) => {
                    return (
                      <Fragment key={attachment.url}>
                        {attachment.type.includes("pdf") ? (
                          <div
                            className="bg-white w-16 h-12 rounded cursor-pointer flex- flex-col"
                            onClick={() =>
                              window.open(attachment.url, "_blank")
                            }
                          >
                            <div className="border-b border-x-0 border-t-0 border-solid border-b-gray-20 h-8 flex w-full">
                              <PdfDocument className="m-auto w-4 h-5" />
                            </div>
                            <Label
                              size={LABEL_SIZES.X_SMALL}
                              color="text-secondary"
                              className="font-medium truncate mx-2xs"
                            >
                              {attachment.name}
                            </Label>
                          </div>
                        ) : (
                          <img
                            src={attachment.url}
                            className="w-16 h-12 min-w-16 min-h-12 rounded cursor-pointer object-cover"
                            onClick={(e) => {
                              setImagePreview({
                                ...imagePreviewOptions,
                                openedImage: e.target.src,
                                isOpen: true,
                              });
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
