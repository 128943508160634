import ChevronDownIcon from "@shared/svg/chevron-down.svg?react";
import CustomFieldsIcon from "@shared/svg/custom-fields.svg?react";
import DescriptionIcon from "@shared/svg/left-align.svg?react";
import PartIcon from "@shared/svg/part.svg?react";
import ProcedureIcon from "@shared/svg/procedures.svg?react";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { Paperclip } from "phosphor-react";
import { useCallback, useState } from "react";

export const ACCORDION_VARIANTS = {
  PROCEDURES: "procedures",
  DESCRIPTION: "description",
  CUSTOM_FIELDS: "customFields",
  ATTACHMENTS: "attachments",
  PARTS: "parts",
  OTHER: "other",
} as const;

export type AccordionVariantType =
  (typeof ACCORDION_VARIANTS)[keyof typeof ACCORDION_VARIANTS];

const getIcon = (variant: AccordionVariantType) => {
  switch (variant) {
    case ACCORDION_VARIANTS.PROCEDURES:
      return <ProcedureIcon width={16} height={16} />;

    case ACCORDION_VARIANTS.DESCRIPTION:
      return <DescriptionIcon width={16} height={16} />;

    case ACCORDION_VARIANTS.CUSTOM_FIELDS:
      return <CustomFieldsIcon width={16} height={16} />;

    case ACCORDION_VARIANTS.PARTS:
      return <PartIcon width={16} height={16} />;

    case ACCORDION_VARIANTS.ATTACHMENTS:
      return <Paperclip size={16} />;

    case ACCORDION_VARIANTS.OTHER:
      return null;
  }
};

type AccordionPropsType = {
  variant?: AccordionVariantType;
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  className?: string;
  contentClassName?: string;
  rightIcon?: React.ReactNode;
};

const Accordion = ({
  variant = ACCORDION_VARIANTS.OTHER,
  title,
  icon = null,
  children,
  defaultExpanded = false,
  className = "",
  contentClassName = "",
  rightIcon = null,
}: AccordionPropsType) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const toggleAccordion = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  return (
    <div
      className={`flex flex-col border border-solid border-primary bg-primary rounded-lg ${className}`}
    >
      <div
        className={`flex items-center justify-between ${
          expanded ? "px-lg pt-lg pb-md" : "p-lg"
        }  cursor-pointer`}
        onClick={toggleAccordion}
      >
        <div className="flex items-center gap-md">
          <div className="w-2xl h-2xl bg-brand-lightest text-brand flex items-center justify-center rounded-full">
            {icon || getIcon(variant)}
          </div>
          <Label size={LABEL_SIZES.SMALL}>{title}</Label>
          {rightIcon}
        </div>
        <ChevronDownIcon
          width={20}
          height={20}
          className={`text-secondary duration-200 ${
            expanded ? "rotate-180" : ""
          }`}
        />
      </div>

      {expanded && (
        <div className={`px-lg pb-lg pt-0 ${contentClassName}`}>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
